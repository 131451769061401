import { SessionServiceAPI } from '@wix/thunderbolt-symbols'
import { BOOTSTRAP_DATA, SESSION_SERVICE, PLATFORM_ESSENTIALS, PLATFORM_BI_LOGGER } from './moduleNames'
import { BootstrapData } from '../../types'
import { ViewerPlatformEssentials } from '@wix/fe-essentials-viewer-platform'
import { platformBiLoggerFactory } from '../bi/biLoggerFactory'

const PlatformBiLogger = (boostrapData: BootstrapData, sessionService: SessionServiceAPI, essentials: ViewerPlatformEssentials) => {
	const { bi: biData, site, location } = boostrapData.platformEnvData

	return platformBiLoggerFactory({
		factory: essentials.biLoggerFactory,
		sessionService,
		location,
		biData,
		site,
	})
}
export default {
	factory: PlatformBiLogger,
	deps: [BOOTSTRAP_DATA, SESSION_SERVICE, PLATFORM_ESSENTIALS],
	name: PLATFORM_BI_LOGGER,
}
